import { AxiosError } from 'axios';

import { isArray, isNonEmptyArray, isNonEmptyPlainObject, isString } from '../../typeguards';
import { FieldErrorDetails, GraphqlErrorResponse } from '../types';
import { ContextData } from './http-exception';

const EXCLUDED_MESSAGES = ['error_in_previous_query'];

const flatten = (array: Record<string, unknown>[]): Record<string, unknown> => {
  return array.reduce((acc, object) => ({ ...acc, ...object }), {});
};

const extractErrors = (previousError?: AxiosError): any[] => {
  const { data } = { ...previousError?.response };
  if (isNonEmptyArray(data?.errors)) return data?.errors;
  if (isNonEmptyArray(data)) return data;
  return [];
};

export const extractMessages = (previousError?: AxiosError): string[] => {
  const errors = extractErrors(previousError);
  return errors.map(({ message }) => !EXCLUDED_MESSAGES.includes(message) && message).filter(isString);
};

const extractDetail = (details: FieldErrorDetails[]): ContextData => {
  return details.reduce((acc: ContextData, field) => {
    const fieldObject = flatten(Object.values(field).map(flatten));
    return { ...acc, ...fieldObject };
  }, {});
};

export const extractDetails = (previousError?: AxiosError): ContextData => {
  const errors = extractErrors(previousError) as GraphqlErrorResponse[];
  const data = errors
    .map(({ extensions }) => {
      const details = extensions?.details;
      return isArray(details) ? extractDetail(details) : details;
    })
    .filter(isNonEmptyPlainObject);
  return flatten(data);
};
