import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpBadGateway extends HttpException {
  static readonly STATUS = 502;
  static readonly TYPE = 'Bad gateway';
  static readonly NAME = 'HttpBadGateway';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpBadGateway.TYPE, ...props, status: HttpBadGateway.STATUS });
    Object.setPrototypeOf(this, HttpBadGateway.prototype);
    this.name = HttpBadGateway.NAME;
  }
}
