import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpGatewayTimeout extends HttpException {
  static readonly STATUS = 504;
  static readonly TYPE = 'Gateway timeout';
  static readonly NAME = 'HttpGatewayTimeout';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpGatewayTimeout.TYPE, ...props, status: HttpGatewayTimeout.STATUS });
    Object.setPrototypeOf(this, HttpGatewayTimeout.prototype);
    this.name = HttpGatewayTimeout.NAME;
  }
}
