import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpBadRequest extends HttpException {
  static readonly STATUS = 400;
  static readonly TYPE = 'Bad request';
  static readonly NAME = 'HttpBadRequest';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpBadRequest.TYPE, ...props, status: HttpBadRequest.STATUS });
    Object.setPrototypeOf(this, HttpBadRequest.prototype);
    this.name = HttpBadRequest.NAME;
  }
}
