import styled from '@emotion/styled';
import { Snackbar as MUISnackbar } from '@mui/material';

import { BREAKPOINTS, RADIUSES, TYPOGRAPHY } from '../../variables';

type StyledSnackbarProps = {
  bgcolor: string;
};

export const Snackbar = styled(MUISnackbar)`
  .MuiSnackbarContent-root {
    flex-direction: row;
    border-radius: ${RADIUSES.md};
    background-color: ${(props: StyledSnackbarProps) => `${props.bgcolor}`};
  }

  .MuiSnackbarContent-message {
    flex: 1;
    display: flex;
    font-family: ${TYPOGRAPHY.SANS_SERIF};
  }

  .MuiSnackbarContent-action {
    margin-right: 0;
  }

  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    bottom: 0;
    left: 0;
    right: 0;

    .MuiSnackbarContent-root {
      border-radius: 0;
    }
  }
`;
