import { createContext, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Snackbar, SnackbarProps } from './Snackbar';

export const OUTLET_ID = 'snackbar-outlet';

export interface SnackbarContext {
  showSnackbar: (props: Exclude<SnackbarProps, 'handleClose' | 'renderInPortal'>) => void;
}

const Context = createContext<SnackbarContext>({} as SnackbarContext);

export interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [outletElement, setOutletElement] = useState<HTMLElement>();
  const [snackbarValues, setSnackbarValues] = useState<SnackbarProps>();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const showSnackbar = (props: SnackbarProps) => {
    setSnackbarValues(props);
    setIsSnackbarVisible(true);
  };

  useEffect(() => {
    const outlet = document.getElementById(OUTLET_ID);
    if (outlet == null) {
      const alertsOutlet = document.createElement('div');
      alertsOutlet.id = OUTLET_ID;
      document.body.appendChild(alertsOutlet);
      setOutletElement(alertsOutlet);
    } else {
      setOutletElement(outlet);
    }

    return () => {
      if (outletElement != null) {
        document.body.removeChild(outletElement);
      }
    };
  }, []);

  return (
    <Context.Provider value={{ showSnackbar }}>
      {children}
      {outletElement != null &&
        createPortal(
          <Snackbar
            closeButton
            {...snackbarValues}
            open={isSnackbarVisible}
            handleClose={() => {
              setIsSnackbarVisible(false);

              // We want to wait for the exit animation to finish before removing the options
              setTimeout(() => {
                setSnackbarValues(undefined);
              }, 500);
            }}
          />,
          document.getElementById(OUTLET_ID) as Element,
        )}
    </Context.Provider>
  );
};

export function useSnackbar() {
  return useContext(Context);
}
