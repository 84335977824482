import { Logo } from '@sortlist-frontend/design-system';
import { TFunction } from '@sortlist-frontend/translation/ssr';

export const GlobalErrorFallback =
  (t: TFunction) =>
  ({ eventId }: { error: Error; componentStack: string | null; eventId: string | null }) => (
    <div
      data-testid="global-error-boundary-container"
      className="layout-fill flex layout-column bg-secondary-900 layout-align-start-stretch">
      <div className="flex layout-column p-16 layout-align-center-center text-center">
        <Logo type="monogram" inversion="positive" height={64} />
        <h2 className="h5 bold mb-8 text-secondary-100 mt-64">{t('common:pageErrors.sorry')}</h2>
        <p className="text-secondary-300 small">Error id: {eventId}</p>
        <a className="text-underline text-secondary-100 mt-32" href={`/`}>
          {t('common:error.backToHome')}
        </a>
      </div>
    </div>
  );

export const ErrorFallback =
  (t: TFunction) =>
  ({ eventId }: { error: Error; componentStack: string | null; eventId: string | null }) => (
    <div
      data-testid="error-boundary-container"
      className="layout-fill flex layout-column bg-secondary-900 layout-align-start-stretch">
      <div className="flex layout-column p-16 layout-align-center-center text-center">
        <Logo type="monogram" inversion="positive" height={64} />
        <h2 className="h5 bold mb-8 text-secondary-100 mt-64">{t('common:pageErrors.sorry')}</h2>
        <p className="text-secondary-300 small">Error id: {eventId}</p>
        <a className="text-underline text-secondary-100 mt-32" href={`/`}>
          {t('common:error.backToHome')}
        </a>
      </div>
    </div>
  );

export const errorHandler = (error: Error, componentStack: string, eventId: string) => {
  // Do something with the error
  // Errors are already logged to Sentry so no need to specifically log to Sentry here
};
