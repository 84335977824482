import { useMediaQuery as useMediaQueryMui } from '@mui/material';

import { BREAKPOINTS } from '../variables/breakpoints';

export type UseMediaQuery = {
  media: {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    gtXs: boolean;
    gtSm: boolean;
    gtMd: boolean;
    gtLg: boolean;
  };
};

export const useMediaQuery = (): UseMediaQuery => {
  const xs = useMediaQueryMui(`(max-width: ${BREAKPOINTS.xs})`);
  const sm = useMediaQueryMui(`(min-width: ${BREAKPOINTS.xs}) and (max-width: ${BREAKPOINTS.sm})`);
  const md = useMediaQueryMui(`(min-width: ${BREAKPOINTS.sm}) and (max-width: ${BREAKPOINTS.md})`);
  const lg = useMediaQueryMui(`(min-width: ${BREAKPOINTS.md}) and (max-width: ${BREAKPOINTS.lg})`);
  const xl = useMediaQueryMui(`(min-width: ${BREAKPOINTS.lg})`);
  const gtXs = useMediaQueryMui(`(min-width: ${BREAKPOINTS.xs})`);
  const gtSm = useMediaQueryMui(`(min-width: ${BREAKPOINTS.sm})`);
  const gtMd = useMediaQueryMui(`(min-width: ${BREAKPOINTS.md})`);
  const gtLg = useMediaQueryMui(`(min-width: ${BREAKPOINTS.lg})`);

  return {
    media: {
      xs: xs,
      sm: sm,
      md: md,
      lg: lg,
      xl: xl,
      gtXs: gtXs,
      gtSm: gtSm,
      gtMd: gtMd,
      gtLg: gtLg,
    },
  };
};
