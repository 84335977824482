import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpRequestTimeout extends HttpException {
  static readonly STATUS = 408;
  static readonly TYPE = 'Request timeout';
  static readonly NAME = 'HttpRequestTimeout';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpRequestTimeout.TYPE, ...props, status: HttpRequestTimeout.STATUS });
    Object.setPrototypeOf(this, HttpRequestTimeout.prototype);
    this.name = HttpRequestTimeout.NAME;
  }
}
