import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpNotImplemented extends HttpException {
  static readonly STATUS = 501;
  static readonly TYPE = 'Not implemented';
  static readonly NAME = 'HttpNotImplemented';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpNotImplemented.TYPE, ...props, status: HttpNotImplemented.STATUS });
    Object.setPrototypeOf(this, HttpNotImplemented.prototype);
    this.name = HttpNotImplemented.NAME;
  }
}
