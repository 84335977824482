import type { FC, SVGProps } from 'react';

export const SortlistMonogramRoundedPrimaryPositive: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 2000 2000"
      version="1.1"
      viewBox="0 0 2000 2000"
      xmlSpace="preserve"
      {...props}>
      <path
        fill="#FFF"
        d="M2000 1001.6c-.5 925.8-72.7 997.9-998.5 998.4h-3.1C72.6 1999.5.5 1927.3 0 1001.5v-3.1C.5 72.6 72.7.5 998.5 0h3.1c925.8.5 998 72.7 998.5 998.4-.1 1.1-.1 2.1-.1 3.2z"></path>
      <g fill="#020219">
        <path d="M544.1 1390l74.1-114.3c78.4 57.1 171.1 90 252.4 90 78.4 0 125.5-32.8 125.5-84.3 0-58.5-68.4-78.5-156.8-101.4-159.7-42.9-260.9-87.1-260.9-221.4 0-137.1 112.6-231.4 278-231.4 99.8 0 201 35.7 280.9 88.6l-67 117.1c-69.9-44.3-146.9-72.9-213.9-72.9-65.6 0-116.9 30-116.9 81.4 0 48.6 45.6 61.4 158.3 95.7 124 34.3 259.5 75.7 259.5 228.6 0 142.8-118.3 234.3-289.4 234.3-114.2 0-235.4-40-323.8-110zM1455.9 500h-171.2v1000h171.2V500z"></path>
      </g>
    </svg>
  );
};
