import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpServiceUnavailable extends HttpException {
  static readonly STATUS = 503;
  static readonly TYPE = 'Service unavailable';
  static readonly NAME = 'HttpServiceUnavailable';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpServiceUnavailable.TYPE, ...props, status: HttpServiceUnavailable.STATUS });
    Object.setPrototypeOf(this, HttpServiceUnavailable.prototype);
    this.name = HttpServiceUnavailable.NAME;
  }
}
