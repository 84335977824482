import { isBrowser } from '@sortlist-frontend/utils';

export const coreApiUrl = isBrowser()
  ? process.env.NEXT_PUBLIC_CORE_API_URL_CLOUDFLARE
  : process.env.NEXT_PUBLIC_CORE_API_URL_INTERNAL;

export const publicApiUrl = isBrowser()
  ? process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_CLOUDFLARE
  : process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_INTERNAL;

export const publicLegacyApiUrl = isBrowser()
  ? process.env.NEXT_PUBLIC_API_BASE_URL
  : process.env.NEXT_PUBLIC_PUBLIC_LEGACY_APP_URL_INTERNAL;
