import { createContext, ReactNode, useContext } from 'react';

import { getPublicApiRequestHomeLink, getPublicApiRequestLink, PublicApiRepo } from '_core/repos/public-api.repo';

export type NavigationContextState = {
  pathname: string;
  asPath: string;
  locale?: string;
  query: Record<string, string | string[] | undefined>;
  push: (url: string, as?: string, options?: Record<string, unknown>) => Promise<boolean>;
};

const NavigationContext = createContext<NavigationContextState | undefined>(undefined);

type Props = NavigationContextState & {
  children: ReactNode;
};

// Needed because in next/router usage is different in app vs pages router
export const NavigationContextProvider = (props: Props) => {
  const { asPath, pathname, query, push, children, locale } = props;

  if (locale != null) {
    PublicApiRepo.publicApiUrl = getPublicApiRequestLink(origin, locale, asPath.replace('/hidden', ''));
    PublicApiRepo.publicApiNavBarUrl = getPublicApiRequestHomeLink(origin, locale);
  }

  return (
    <NavigationContext.Provider
      value={{
        pathname,
        query,
        asPath,
        push,
      }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context === undefined || context === null) {
    throw new Error(`useNavigationContext must be used within an NavigationContextProvider`);
  }
  return context;
};
