import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpBadMapping extends HttpException {
  static readonly STATUS = 421;
  static readonly TYPE = 'Bad mapping';
  static readonly NAME = 'HttpBadMapping';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpBadMapping.TYPE, ...props, status: HttpBadMapping.STATUS });
    Object.setPrototypeOf(this, HttpBadMapping.prototype);
    this.name = HttpBadMapping.NAME;
  }
}
