import { AxiosError } from 'axios';

import { JsonType } from '../../typeguards';
import { extractDetails, extractMessages } from './utils';

export type ServerErrorPayload =
  | {
      type: 'json';
      content: JsonType;
    }
  | {
      type: 'text';
      content: string;
    };

export type HttpExceptionProps = {
  status: number;
  type?: string;
  message?: string;
  url?: string;
  method?: string;
  previousError?: AxiosError;
  stackTrace?: string;
  service?: string;
  errorPayload?: ServerErrorPayload;
};

export type CustomExceptionProps = Omit<HttpExceptionProps, 'status'>;

type Primitive = number | string | boolean | bigint | symbol | null | undefined;
export type ContextData = Record<string, unknown>;

/**
 * The base HTTPException class
 */
export class HttpException extends Error {
  public readonly status: number;
  public readonly type: string;
  public readonly message: string;
  public readonly url?: string;
  public readonly method?: string;
  public readonly stackTrace: string;
  public readonly errorPayload?: ServerErrorPayload;
  public readonly service?: string;
  public readonly details?: ContextData;
  public readonly props: HttpExceptionProps;
  public readonly previousError!: AxiosError | null;

  public readonly tags: Record<string, Primitive>;
  public readonly contexts: Record<string, ContextData | undefined>;

  constructor(props: HttpExceptionProps) {
    super();

    Object.setPrototypeOf(this, HttpException.prototype);

    const {
      status,
      method = 'URL',
      url = 'unknown',
      previousError,
      stackTrace: propsStackTrace,
      errorPayload,
      type,
      message,
      service,
    } = props;

    const innerMessage = extractMessages(previousError).join(', ');
    const messageText = innerMessage || previousError?.message || previousError?.name || message;
    const details = extractDetails(previousError);
    const stackTrace = propsStackTrace ?? previousError?.stack ?? '';
    const errorResponse = previousError as unknown as ContextData | undefined;

    this.status = status;
    this.url = url;
    this.method = method;
    this.type = type ?? 'Unknown error';
    this.stackTrace = stackTrace;
    this.service = service;
    this.props = props;

    this.name = 'HttpException';
    this.message = messageText?.trim() ?? 'Unknown error';

    this.tags = { status, url, method, type };
    this.contexts = { details, errorPayload, errorResponse };
  }

  getHttpStatus(): number {
    return this.status;
  }

  toString(): string {
    return `${this.name} (${this.status}): ${this.message}`.trim();
  }
}
