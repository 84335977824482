import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpForbidden extends HttpException {
  static readonly STATUS = 403;
  static readonly TYPE = 'Forbidden';
  static readonly NAME = 'HttpForbidden';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpForbidden.TYPE, ...props, status: HttpForbidden.STATUS });
    Object.setPrototypeOf(this, HttpForbidden.prototype);
    this.name = HttpForbidden.NAME;
  }
}
