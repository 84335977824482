import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpInternalServerError extends HttpException {
  static readonly STATUS = 500;
  static readonly TYPE = 'Internal server error';
  static readonly NAME = 'HttpInternalServerError';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpInternalServerError.TYPE, ...props, status: HttpInternalServerError.STATUS });
    Object.setPrototypeOf(this, HttpInternalServerError.prototype);
    this.name = HttpInternalServerError.NAME;
  }
}
