import { isNonEmptyString, isPlainObject } from '../typeguards';

type FieldErrorMessage = Record<string, string[]>;
type FieldErrorDetail = Record<string, string>;
export type FieldErrorDetails = Record<string, FieldErrorDetail[]>;

export type GraphqlErrorResponse = {
  message: string;
  extensions: {
    details: FieldErrorDetails[];
    messages: FieldErrorMessage[];
  };
};

/**
 * Shape of the core api errors returned when 422 is thrown
 */

export type CoreApiErrorPayload = {
  code: 'record_invalid' | string;
  errors?: Record<string, { error: string; value?: string }[]>;
  error_id?: string;
};

export const isCoreApiErrorPayload = (value: unknown): value is CoreApiErrorPayload => {
  return isNonEmptyString((value as CoreApiErrorPayload).code) && isPlainObject((value as CoreApiErrorPayload).errors);
};

export type PublicApiErrorPayload = {
  status: number;
  source: string | null;
  title: string;
  detail: string;
  id: number | null;
  code: string | null;
};
