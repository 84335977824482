import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpUnauthorized extends HttpException {
  static readonly STATUS = 401;
  static readonly TYPE = 'Unauthorized';
  static readonly NAME = 'HttpUnauthorized';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpUnauthorized.TYPE, ...props, status: HttpUnauthorized.STATUS });
    Object.setPrototypeOf(this, HttpUnauthorized.prototype);
    this.name = HttpUnauthorized.NAME;
  }
}
