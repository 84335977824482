import { ConfigPublicAppLinks, ConfigPublicAppLinksKeys, linksConfigFactory } from '_config/site.links.config';

export type GetUrlType = <T extends ConfigPublicAppLinksKeys>(key: T, params?: InferedParamsType<T>) => string;

// This type is infering the type of the params depending on the key.
// That way, when you use getUrl in the code, depending on what key you provide,
// you'll have the relevant types and autocomplete available.
type InferedParamsType<T extends ConfigPublicAppLinksKeys> = ConfigPublicAppLinks[T] extends (
  ...args: infer P
) => string
  ? P[0]
  : undefined;

type Config = {
  origin?: string | null;
  locale: string;
  /** Default: linksConfigFactory. Mostly used to ease mocking */
  linkConfigFactory?: typeof linksConfigFactory;
};

type LinkFunction = (props: unknown) => string;

export class PublicAppLinks {
  private readonly linkMap: ConfigPublicAppLinks;
  constructor(config: Config) {
    const { origin = null, locale, linkConfigFactory: linksBuilder = linksConfigFactory } = config;
    this.linkMap = linksBuilder({ origin, locale }).publicApp;
  }

  getUrl = <T extends ConfigPublicAppLinksKeys>(key: T, params?: InferedParamsType<T>): string => {
    const val = this.linkMap[key];
    return typeof val === 'function' ? (val as LinkFunction)(params ?? {}) : val;
  };
}
