import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpNotFound extends HttpException {
  static readonly STATUS = 404;
  static readonly TYPE = 'Not found';
  static readonly NAME = 'HttpNotFound';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpNotFound.TYPE, ...props, status: HttpNotFound.STATUS });
    Object.setPrototypeOf(this, HttpNotFound.prototype);
    this.name = HttpNotFound.NAME;
  }
}
