import type { FC, SVGProps } from 'react';

export const SortlistPlusMonogramRoundedPrimaryPositive: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="78" fill="none" viewBox="0 0 78 78" {...props}>
      <g clipPath="url(#clip0_935_13307)">
        <path
          fill="#000"
          d="M78 39.062C77.98 75.17 75.165 77.981 39.059 78h-.121C2.83 77.98.019 75.165 0 39.059v-.121C.02 2.83 2.835.019 38.941 0h.121C75.17.02 77.984 2.835 78.004 38.938c-.004.042-.004.081-.004.124z"></path>
        <path
          fill="#fff"
          d="M19 52.26l2.502-3.886c2.648 1.941 5.778 3.06 8.524 3.06 2.647 0 4.238-1.115 4.238-2.866 0-1.99-2.31-2.67-5.296-3.448-5.392-1.459-8.81-2.961-8.81-7.528 0-4.661 3.803-7.867 9.388-7.867 3.37 0 6.788 1.214 9.486 3.012l-2.263 3.982c-2.36-1.507-4.96-2.479-7.223-2.479-2.215 0-3.948 1.02-3.948 2.768 0 1.652 1.54 2.087 5.346 3.253 4.187 1.167 8.763 2.574 8.763 7.773 0 4.855-3.995 7.966-9.773 7.966-3.856 0-7.949-1.36-10.934-3.74zM49.79 22h-5.78v34h5.78V22zM59.727 45.649V32.35h3.34V45.65h-3.34zm-4.934-4.968v-3.362H68v3.362H54.793z"></path>
      </g>
      <defs>
        <clipPath id="clip0_935_13307">
          <path fill="#fff" d="M0 0H78V78H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
