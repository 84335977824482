import { AxiosError } from 'axios';

import {
  HttpBadGateway,
  HttpBadMapping,
  HttpBadRequest,
  HttpException,
  HttpForbidden,
  HttpGatewayTimeout,
  HttpInternalServerError,
  HttpMethodNotAllowed,
  HttpNotFound,
  HttpNotImplemented,
  HttpRequestTimeout,
  HttpServiceUnavailable,
  HttpUnauthorized,
  HttpUnprocessableEntity,
} from './http';
import { ServerErrorPayload } from './http/http-exception';
import { PublicApiErrorPayload } from './types';

/**
 * Utility to map axios errors to http-exceptions and provide support
 * to add url to the exception message
 *
 * @param e - Error returned by axios
 * @param url - Url added to
 * @param unexpectedErrorStatusCode - Default status code if exception is not supported
 * @return HttpException or any supported concrete implementation
 */
export const mapErrorToHttpException = ({
  error,
  url,
  errorPayload,
  unexpectedErrorStatusCode = 500,
  service,
}: {
  error: AxiosError;
  url?: string;
  errorPayload?: ServerErrorPayload;
  unexpectedErrorStatusCode?: number;
  service?: string;
}): HttpException => {
  const { stack: stackTrace } = error;
  const simpleErrorBody = { url, previousError: error, stackTrace, service };
  const fullErrorBody = { ...simpleErrorBody, errorPayload };

  if (error?.code === 'ECONNABORTED') {
    return new HttpRequestTimeout(simpleErrorBody);
  } else if (error?.isAxiosError) {
    const status: number = error.response?.status ?? unexpectedErrorStatusCode;
    switch (status) {
      case 400:
        return new HttpBadRequest(fullErrorBody);
      case 401:
        return new HttpUnauthorized(fullErrorBody);
      case 403:
        return new HttpForbidden(fullErrorBody);
      case 404:
        return new HttpNotFound(fullErrorBody);
      case 421:
        return new HttpBadMapping(fullErrorBody);
      case 422:
        return new HttpUnprocessableEntity(fullErrorBody);
      case 405:
        return new HttpMethodNotAllowed(fullErrorBody);
      case 500:
        return new HttpInternalServerError(fullErrorBody);
      case 501:
        return new HttpNotImplemented(fullErrorBody);
      case 502:
        return new HttpBadGateway(fullErrorBody);
      case 503:
        return new HttpServiceUnavailable(fullErrorBody);
      case 504:
        return new HttpGatewayTimeout(fullErrorBody);
      default:
        return new HttpException({ status, ...fullErrorBody });
    }
  }
  // Fallback to
  return new HttpException({
    status: unexpectedErrorStatusCode,
    message: `Unexpected error: ${error.name ?? ''}: ${error.message ?? ''}`,
    ...fullErrorBody,
  });
};
