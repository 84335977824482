import type { FC, SVGProps } from 'react';

import {
  SortlistLogotypePrimaryNegative,
  SortlistLogotypePrimaryPositive,
  SortlistMonogramRoundedPrimaryNegative,
  SortlistMonogramRoundedPrimaryPositive,
  SortlistPlusLogotypePrimaryNegative,
  SortlistPlusLogotypePrimaryPositive,
  SortlistPlusMonogramRoundedPrimaryNegative,
  SortlistPlusMonogramRoundedPrimaryPositive,
} from './assets';

export type LogoProps = {
  variant?: 'brand' | 'plan';
  type?: 'monogram' | 'logotype';
  inversion?: 'positive' | 'negative';
} & SVGProps<SVGSVGElement>;

export const Logo: FC<LogoProps> = (props) => {
  const { variant, type, inversion, ...restProps } = props;

  if (variant === 'plan') {
    if (type === 'logotype') {
      if (inversion === 'positive') {
        return (
          <SortlistPlusLogotypePrimaryPositive data-testid={'sortlist-plus-logotype-primary-positive'} {...restProps} />
        );
      } else {
        return (
          <SortlistPlusLogotypePrimaryNegative data-testid={'sortlist-plus-logotype-primary-negative'} {...restProps} />
        );
      }
    } else {
      if (inversion === 'positive') {
        return (
          <SortlistPlusMonogramRoundedPrimaryPositive
            data-testid={'sortlist-plus-monogram-rounded-primary-positive'}
            {...restProps}
          />
        );
      } else {
        return (
          <SortlistPlusMonogramRoundedPrimaryNegative
            data-testid={'sortlist-plus-monogram-rounded-primary-negative'}
            {...restProps}
          />
        );
      }
    }
  } else {
    if (type === 'logotype') {
      if (inversion === 'positive') {
        return <SortlistLogotypePrimaryPositive data-testid="sortlist-logotype-primary-positive" {...restProps} />;
      } else {
        return <SortlistLogotypePrimaryNegative data-testid={'sortlist-logotype-primary-negative'} {...restProps} />;
      }
    } else if (type === 'monogram') {
      if (inversion === 'positive') {
        return (
          <SortlistMonogramRoundedPrimaryPositive
            data-testid={'sortlist-monogram-rounded-primary-positive'}
            {...restProps}
          />
        );
      } else {
        return (
          <SortlistMonogramRoundedPrimaryNegative
            data-testid={'sortlist-monogram-rounded-primary-negative'}
            {...restProps}
          />
        );
      }
    } else {
      return <SortlistLogotypePrimaryNegative data-testid={'sortlist-logotype-primary-negative'} {...restProps} />;
    }
  }
};
