import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpMethodNotAllowed extends HttpException {
  static readonly STATUS = 405;
  static readonly TYPE = 'Method not allowed';
  static readonly NAME = 'HttpMethodNotAllowed';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpMethodNotAllowed.TYPE, ...props, status: HttpMethodNotAllowed.STATUS });
    Object.setPrototypeOf(this, HttpMethodNotAllowed.prototype);
    this.name = HttpMethodNotAllowed.NAME;
  }
}
