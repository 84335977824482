import { isNonEmptyPlainObject, isNonEmptyString } from '@sortlist-frontend/utils';

import { DomainInfo } from '_backend/integration/domain-info';

type LinksConfigProps = {
  origin?: string | null;
  locale?: string | null;
};

export type SiteLinks = ReturnType<typeof linksConfigFactory>;

export type ConfigPublicAppLinks = SiteLinks['publicApp'];
export type ConfigSocialLinks = SiteLinks['social'];

export type ConfigPublicAppLinksKeys = keyof ConfigPublicAppLinks;
export type ConfigSocialLinksKeys = keyof ConfigSocialLinks;

const getParam = (key: string, value?: string) => (value ? `${key}=${value}` : '');
const getQS = (params?: Record<string, string | undefined>) => {
  if (isNonEmptyPlainObject(params)) {
    const keys = Object.keys(params);
    const query = keys.map((key) => getParam(key, params[key])).join('&');
    return query ? `?${query}` : '';
  }
  return '';
};
const getAnchor = (name?: string) => (name ? `#${name}` : '');

export const linksConfigFactory = (props: LinksConfigProps) => {
  const { origin = null, locale = 'en' } = props;
  const domainInfo = DomainInfo.getFromOrigin(origin);
  const prefix = domainInfo?.getLocalizedUrl(locale) || '';

  return {
    publicApp: {
      'client.advisors': `${prefix}/clients/products/advisors`,
      'client.howItWorks': `${prefix}/how-it-works/client`,
      'client.testimonials': `${prefix}/stories/client`,
      'client.explore': `${prefix}/explore`,
      'agency.claim.landing': ({ ref }: { ref?: string }) => `${prefix}/claim/agency${getQS({ ref })}`,
      'agency.claim.confirm': ({ slugOrUuid, name }: { slugOrUuid?: string; name?: string }) =>
        slugOrUuid
          ? `${prefix}/claim/agency/confirm/${slugOrUuid}${getQS({ name })}`
          : `${prefix}/claim/agency/confirm${getQS({ name })}`,
      'agency.claim.edit': ({
        claimSessionId,
        uuid,
        name,
      }: {
        claimSessionId: string;
        uuid?: string; // if not provided, will be new record
        name?: string;
      }) => {
        const url = `${prefix}/claim/agency/edit/${claimSessionId}`;
        return isNonEmptyString(uuid) ? `${url}/${uuid}${getQS({ name })}` : `${url}${getQS({ name })}`;
      },
      'agency.search': `${prefix}/search`,
      'agency.howItWorks': `${prefix}/how-it-works/agency`,
      'providers.pricing': `${prefix}/providers/pricing`,
      'agency.testimonials': `${prefix}/stories/agency`,
      'agency.profile': ({ slug, anchor }: { slug: string; anchor?: string }) =>
        `${prefix}/agency/${slug}${getAnchor(anchor)}`,
      'agency.profile.review': ({ slug }: { slug: string }) => `${prefix}/agency/${slug}/review`,
      'agency.portfolio.workSlug': ({ slug, workSlug }: { slug: string; workSlug: string }) =>
        `${prefix}/agency/${slug}/portfolio/${workSlug}`,
      'project.new': ({ autoload, ref }: { autoload?: string; ref?: string }) =>
        `${prefix}/project/new${getQS({ autoload, ref })}`,
      join: `${prefix}/join`,
      selectLoginType: `${prefix}/select-account`,
      'legal.privacyPolicy': `${prefix}/privacy`,
      'legal.impressum': `${prefix}/impressum`,
      'legal.terms': `${prefix}/terms`,
      'info.about': `${prefix}/about`,
      'info.contact': `${prefix}/contact`,
      'info.countries': `${prefix}/countries`,
      'info.jobs': 'https://sortlist.breezy.hr/',
      'longtails.hubPage': ({ expertiseSlug }: { expertiseSlug: string }) => `${prefix}/${expertiseSlug}`,
      'info.help': `https://help.sortlist.com/${locale}`,

      // Providers
      providers: ({ ref }: { ref?: string }) => `${prefix}/providers${getQS({ ref })}`,
      'providers.visibility': `${prefix}/providers/visibility`,
      'providers.opportunity': `${prefix}/providers/opportunity`,

      // Refund
      'legal.refundPolicy': `${prefix}/providers/opportunity/refund-policy`,

      //stories
      stories: `${prefix}/stories`,
      story: ({ storySlug }: { storySlug: string }) => `${prefix}/story/${storySlug}`,

      // lobby
      lobby: ({ token }: { token: string }) => `${prefix}/project/dashboard/${token}`,
    },
    social: {
      x: 'https://x.com/sortlist',
      facebook: 'https://www.facebook.com/wearesortlist',
      linkedin: 'https://www.linkedin.com/company/sortli-st/',
      instagram: 'https://www.instagram.com/sortlist/',
      youtube: 'https://www.youtube.com/channel/UCsougkJ2hHCTWJD_eywEHpA',
    },
  };
};
