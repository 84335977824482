import { CoreApiErrorPayload, isCoreApiErrorPayload } from '../types';
import { CustomExceptionProps, HttpException } from './http-exception';

export class HttpUnprocessableEntity extends HttpException {
  static readonly STATUS = 422;
  static readonly TYPE = 'Unprocessable entity';
  static readonly NAME = 'HttpUnprocessableEntity';

  constructor(props: CustomExceptionProps) {
    super({ type: HttpUnprocessableEntity.TYPE, ...props, status: HttpUnprocessableEntity.STATUS });
    Object.setPrototypeOf(this, HttpUnprocessableEntity.prototype);
    this.name = HttpUnprocessableEntity.NAME;
  }

  getCoreApiErrorPayload(): CoreApiErrorPayload | null {
    if (
      this.errorPayload !== undefined &&
      this.errorPayload.type === 'json' &&
      isCoreApiErrorPayload(this.errorPayload.content)
    ) {
      return this.errorPayload.content;
    }
    return null;
  }
}
