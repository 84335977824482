import type { FC, SVGProps } from 'react';

export const SortlistLogotypePrimaryPositive: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 283.5 72.3"
      version="1.1"
      viewBox="0 0 283.5 72.3"
      xmlSpace="preserve"
      {...props}>
      <path
        fill="#FFF"
        d="M280.1 60.4c-1.1 1.1-3 1.8-5 1.8-2.7 0-4.9-2.4-4.9-5.8V30.8h11.3V21h-11.3V7.3h-11.4v50.6c0 8.4 5.8 14.4 14.1 14.4 3.9 0 8-1.6 10.8-4l-3.6-7.9z"></path>
      <path
        fill="#FFF"
        d="M24 40.9c-7.7-2.3-10.8-3.2-10.8-6.5 0-3.5 3.5-5.5 8-5.5 4.6 0 9.8 1.9 14.5 4.9l4.6-8c-5.4-3.6-12.3-6-19.1-6-11.2.1-18.9 6.5-18.9 15.8 0 9.1 6.9 12.1 17.7 15 6 1.6 10.7 2.9 10.7 6.9 0 3.5-3.2 5.7-8.5 5.7-5.5 0-11.8-2.2-17.2-6.1l-5 7.8c6 4.8 14.3 7.5 22 7.5 11.6 0 19.7-6.2 19.7-15.9 0-10.4-9.2-13.2-17.7-15.6z"></path>
      <path
        fill="#FFF"
        d="M200.1 13.5c3.7 0 6.6-3.2 6.6-6.8 0-3.6-2.9-6.7-6.6-6.7-3.8 0-6.8 3.1-6.8 6.7s3 6.8 6.8 6.8z"></path>
      <path fill="#FFF" d="M206 21h-11.8v50.1H206V21z"></path>
      <path fill="#FFF" d="M183.8 3.4H172v67.8h11.8V3.4z"></path>
      <path
        fill="#FFF"
        d="M158.9 62.3c-2.7 0-4.9-2.4-4.9-5.8V30.8h11.3V21H154V7.3h-6.5c0 3.5-1.1 6.7-2.9 9.1-2 2.7-5 4.5-8.3 4.5h-1.6l-1.8 10h9.6V58c0 8.4 5.8 14.4 14.1 14.4 3.9 0 8-1.6 10.8-4l-3.4-8c-1.2 1.1-3.1 1.9-5.1 1.9z"></path>
      <path
        fill="#FFF"
        d="M114.4 31.6V21h-11.8v50.1h11.8V46.9c0-9.5 5.5-16 15.1-16l1.9-9.9c-10.8-.1-13.8 4.1-17 10.6z"></path>
      <path
        fill="#FFF"
        d="M70.8 19.9c-14.8 0-27 11.7-27 26.2s12.1 26.3 27 26.3c14.7 0 26.8-11.8 26.8-26.3S85.5 19.9 70.8 19.9zm0 42.4c-8.4 0-15.3-7.3-15.3-16.2 0-8.9 6.9-16.1 15.3-16.1 8.3 0 15.1 7.2 15.1 16.1 0 8.9-6.8 16.2-15.1 16.2z"></path>
      <path
        fill="#FFF"
        d="M236.2 40.9c-7.7-2.3-10.8-3.2-10.8-6.5 0-3.5 3.5-5.5 8-5.5 4.6 0 9.8 1.9 14.5 4.9l4.6-8c-5.4-3.6-12.3-6-19.1-6-11.2 0-18.9 6.4-18.9 15.7 0 9.1 6.9 12.1 17.7 15 6 1.6 10.7 2.9 10.7 6.9 0 3.5-3.2 5.7-8.5 5.7-5.5 0-11.8-2.2-17.2-6.1l-5 7.8c6 4.8 14.3 7.5 22 7.5 11.6 0 19.7-6.2 19.7-15.9 0-10.3-9.2-13.1-17.7-15.5z"></path>
    </svg>
  );
};
