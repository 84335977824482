export const COLORS = {
  PRIMARY_100: '#f6f8fe',
  PRIMARY_200: '#e8edfc',
  PRIMARY_300: '#bac4f7',
  PRIMARY_500: '#200df2',
  PRIMARY_700: '#081f91',
  PRIMARY_900: '#020219',

  SECONDARY_100: '#fbfaf9',
  SECONDARY_200: '#f1edea',
  SECONDARY_300: '#e7dfda',
  SECONDARY_500: '#7e7367',
  SECONDARY_700: '#463f39',
  SECONDARY_900: '#0f0d0a',

  SUCCESS_100: '#f4fbef',
  SUCCESS_200: '#e2f0db',
  SUCCESS_300: '#d1e4cd',
  SUCCESS_500: '#5c9550',
  SUCCESS_700: '#386b2e',
  SUCCESS_900: '#293913',

  DANGER_100: '#fbeff3',
  DANGER_200: '#f7dee8',
  DANGER_300: '#f2c0d5',
  DANGER_500: '#bd0f58',
  DANGER_700: '#8f1941',
  DANGER_900: '#410b1e',

  WARNING_100: '#fbf5e4',
  WARNING_200: '#f9ecd2',
  WARNING_300: '#f8ddb4',
  WARNING_500: '#f9af1f',
  WARNING_700: '#805400',
  WARNING_900: '#322101',

  NEUTRAL_100: '#ffffff',
  NEUTRAL_200: '#ebebeb',
  NEUTRAL_300: '#bfbfbf',
  NEUTRAL_500: '#808080',
  NEUTRAL_700: '#404040',
  NEUTRAL_900: '#000000',

  AGENCY_100: '#f1f9f5',
  AGENCY_200: '#e7f4ee',
  AGENCY_300: '#cbe7da',
  AGENCY_500: '#84c7a9',
  AGENCY_700: '#337055',
  AGENCY_900: '#183528',

  CLIENT_100: '#fbf2ef',
  CLIENT_200: '#f7e4de',
  CLIENT_300: '#f1b6b1',
  CLIENT_500: '#e75555',
  CLIENT_700: '#a32929',
  CLIENT_900: '#521415',
};
