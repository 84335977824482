import { ImageSourceHelper } from '@sortlist-frontend/media';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

// Client company logos
const adobe = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/adobe.svg`);
const amazon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/amazon.svg`);
const audi = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/audi.svg`);
const bbc = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/bbc.svg`);
const blizzard = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/blizzard.svg`);
const bosch = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/bosch.svg`);
const esteeLauder = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/estee-lauder.svg`);
const farm = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/farm.svg`);
const gsk = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/gsk.svg`);
const linkedin = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/linkedin.svg`);
const nespresso = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/nespresso.svg`);
const newBalance = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/new-balance.svg`);
const samsung = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/samsung.svg`);
const unicef = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/unicef.svg`);
const velux = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/velux.svg`);
const yale = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/yale.svg`);

// Media company logos
const bfm = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/bfm.svg`);
const bloovi = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/bloovi.svg`);
const cincodias = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/cincodias.svg`);
const destandaard = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/destandaard.svg`);
const detijd = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/detijd.svg`);
const digimedia = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/digimedia.svg`);
const eleconomista = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/eleconomista.svg`);
const elpais = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/elpais.svg`);
const emprendedores = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/emprendedores.svg`);
const expansion = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/expansion.svg`);
const france2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/france2.svg`);
const jdn = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/jdn.svg`);
const lecho = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/lecho.svg`);
const lemonde = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/lemonde.svg`);
const lesechos = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/lesechos.svg`);
const lesoir = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/lesoir.svg`);
const mediaMarketing = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/media-marketing.svg`);
const strategies = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/strategies.svg`);
const techcrunch = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/techcrunch.svg`);
const tf1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/tf1.svg`);
const tnw = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/tnw.svg`);
const trends = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/trends.svg`);
const tv5 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/tv5.svg`);
const ventureBeat = ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/media/venture-beat.svg`);

export type CompanyType = {
  id: string;
  name: string;
  logoSrc?: string;
  country?: string;
  locale?: string;
};

export const mediaCompaniesData = [
  {
    id: 'bfm',
    name: 'BFM',
    logoSrc: bfm,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'bloovi',
    name: 'Bloovi',
    logoSrc: bloovi,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'cincodias',
    name: 'Cincodias',
    logoSrc: cincodias,
    country: 'ES',
    locale: 'ES',
  },
  {
    id: 'destandaard',
    name: 'DeStandaard',
    logoSrc: destandaard,
    country: 'BE',
    locale: 'NL',
  },
  {
    id: 'detijd',
    name: 'DeTijd',
    logoSrc: detijd,
    country: 'BE',
    locale: 'NL',
  },
  {
    id: 'digimedia',
    name: 'Digimedia',
    logoSrc: digimedia,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'elEconomista',
    name: 'El Economista',
    logoSrc: eleconomista,
    country: 'ES',
    locale: 'ES',
  },
  {
    id: 'elPais',
    name: 'El Pais',
    logoSrc: elpais,
    country: 'ES',
    locale: 'ES',
  },
  {
    id: 'emprendedores',
    name: 'Emprendedores',
    logoSrc: emprendedores,
    country: 'ES',
    locale: 'ES',
  },
  {
    id: 'expansion',
    name: 'Expansion',
    logoSrc: expansion,
    country: '',
    locale: '',
  },
  {
    id: 'france2',
    name: 'France2',
    logoSrc: france2,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'journalDuNet',
    name: 'Journal Du Net',
    logoSrc: jdn,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'lEcho',
    name: "L'Echo",
    logoSrc: lecho,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'leMmonde',
    name: 'Le Monde',
    logoSrc: lemonde,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'lesEchos',
    name: 'Les Echos',
    logoSrc: lesechos,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'leSoir',
    name: 'Le Soir',
    logoSrc: lesoir,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'mediaMarketing',
    name: 'Media Marketing',
    logoSrc: mediaMarketing,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'strategies',
    name: 'Strategies',
    logoSrc: strategies,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'techcrunch',
    name: 'techcrunch',
    logoSrc: techcrunch,
    country: 'USA',
    locale: 'EN',
  },
  {
    id: 'tf1',
    name: 'TF1',
    logoSrc: tf1,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'theNextWeb',
    name: 'The Next Web',
    logoSrc: tnw,
    country: 'NL',
    locale: 'NL',
  },
  {
    id: 'trends',
    name: 'Trends',
    logoSrc: trends,
    country: 'BE',
    locale: 'FR',
  },
  {
    id: 'tv5',
    name: 'TV5',
    logoSrc: tv5,
    country: 'FR',
    locale: 'FR',
  },
  {
    id: 'ventureBeat',
    name: 'Venture Beat',
    logoSrc: ventureBeat,
    country: 'USA',
    locale: 'EN',
  },
];

export const clientCompaniesData = [
  {
    id: 'nespresso',
    name: 'Nespresso',
    logoSrc: nespresso,
  },
  {
    id: 'unicef',
    name: 'Unicef',
    logoSrc: unicef,
  },
  {
    id: 'samsung',
    name: 'Samsung',
    logoSrc: samsung,
  },
  {
    id: 'esteeLauder',
    name: 'Estee Lauder',
    logoSrc: esteeLauder,
  },
  {
    id: 'newBalance',
    name: 'New Balance',
    logoSrc: newBalance,
  },
  {
    id: 'bosch',
    name: 'Bosch',
    logoSrc: bosch,
  },
  {
    id: 'velux',
    name: 'Velux',
    logoSrc: velux,
  },
  {
    id: 'gsk',
    name: 'GSK',
    logoSrc: gsk,
  },
  // {
  //   id: 'bridgestone',
  //   name: 'Bridgestone',
  //   logoSrc: bridgestone,
  // },
  {
    id: 'adobe',
    name: 'Adobe',
    logoSrc: adobe,
  },
  {
    id: 'amazon',
    name: 'Amazon',
    logoSrc: amazon,
  },
  {
    id: 'audi',
    name: 'Audi',
    logoSrc: audi,
  },
  {
    id: 'bbc',
    name: 'BBC',
    logoSrc: bbc,
  },
  {
    id: 'blizzard',
    name: 'Blizzard',
    logoSrc: blizzard,
  },
  {
    id: 'farm',
    name: 'Färm',
    logoSrc: farm,
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
    logoSrc: linkedin,
  },
  {
    id: 'yale',
    name: 'Yale',
    logoSrc: yale,
  },
];
