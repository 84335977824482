const DEFAULT_LOCAL_ORIGIN_DOMAIN = 'www.sortlist-test.com';
const ORIGIN = process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_APP_URL! : null;

// On Sandboxes we don't have data (Seeds) for public-api
// In order to have data we connect to staging with the following function
const getStagingOrigin = (origin: string) => {
  // Scenario 1: https://pr-public-ui-3012-public.sandbox.sortlist.cloud
  // This should request data from staging with: https://www.sortlist-test.com

  // Scenario 2: https://be-pr-public-ui-3012-public.sandbox.sortlist.cloud
  // Link should be: https://www.sortlist-test.be

  // Scenario 3: https://localeOtherThanBe-pr-public-ui-3012-public.sandbox.sortlist.cloud
  // Link should be: https://www-localeOtherThanBe.sortlist-test.com

  const { hostname } = new URL(origin);
  const domainKey = hostname.split('-')[0];

  if (domainKey === 'pr') {
    return 'https://www.sortlist-test.com';
  } else if (domainKey === 'be') {
    return 'https://www.sortlist-test.be';
  } else {
    return `https://www-${domainKey}.sortlist-test.com`;
  }
};

export const getPublicApiRequestLink = (origin: string, locale: string, pathname: string) => {
  const cleanOrigin = origin.includes('public.sandbox.sortlist.cloud') ? getStagingOrigin(origin) : origin;
  const cleanPath = pathname.replace('/embed', '').split('?')[0];
  const cleanUrl = `${cleanOrigin}/${locale}/${encodeURIComponent(cleanPath)}`;

  if (ORIGIN != null) {
    return cleanUrl.replace(ORIGIN, DEFAULT_LOCAL_ORIGIN_DOMAIN).replace('https://', '');
  }
  return cleanUrl.replace('https://', '');
};

export const getPublicApiRequestHomeLink = (origin: string, locale: string) => {
  const cleanOrigin = origin.includes('public.sandbox.sortlist.cloud') ? getStagingOrigin(origin) : origin;
  const cleanUrl = `${cleanOrigin}/${locale}/${encodeURIComponent('/')}`;
  if (ORIGIN != null) {
    return cleanUrl.replace(ORIGIN, DEFAULT_LOCAL_ORIGIN_DOMAIN).replace('https://', '');
  }
  return cleanUrl.replace('https://', '');
};

export class PublicApiRepo {
  public static publicApiUrl = '';
  public static publicApiNavBarUrl = '';

  getReviews = async (): Promise<Reviews> => {
    return new Promise((resolve) => resolve(TRUSTPILOT_DATA));
  };
}
export type Reviews = { reviewsCount: number; reviewsAverage: string };
export const TRUSTPILOT_DATA = { reviewsCount: 516, reviewsAverage: '0.9' };

export const publicApiRepo = new PublicApiRepo();

export const cacheKey = {
  page: (url: string) => `page-${url}`,
  agencies: (url: string, section: 'organic' | 'paid') => `agencies-${url}-${section}`,
  links: (url: string) => `links-${url}`,
  works: (url: string) => `works-${url}`,
  reviews: 'reviews',
};

export const getSortParam = (sort?: string, section = 'organic') => {
  let sortDetails = '';

  if (sort === 'reviews_score' || sort === 'team_size') {
    sortDetails = `&sort=-agencies.${sort}`;
  } else {
    sortDetails = `&sort=-${sort}`;
  }

  return sort !== 'position' && section !== 'paid' ? sortDetails : '';
};
