// eslint-disable-next-line no-restricted-imports
import { TFunction, Trans } from '@sortlist-frontend/translation';
import { createContext, ReactNode, useContext } from 'react';

export interface SharedComponentsContextType {
  t: TFunction;
  locale: string;
  Trans: typeof Trans;
}

const SharedComponentsContext = createContext<SharedComponentsContextType>({} as SharedComponentsContextType);

interface SharedComponentsProviderProps {
  children: ReactNode;
  t: TFunction;
  locale: string;
  Trans: typeof Trans;
}

export const SharedComponentsProvider = ({ children, t, locale, Trans }: SharedComponentsProviderProps) => {
  return <SharedComponentsContext.Provider value={{ t, locale, Trans }}>{children}</SharedComponentsContext.Provider>;
};

export function useSharedComponentsContext() {
  return useContext(SharedComponentsContext);
}
